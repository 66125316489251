<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Locais</li>
    </ol>
  </nav>
  <DataTable v-bind:object=object v-bind:fields=fields v-bind:routeTo=routeTo v-bind:routeNew=routeNew v-bind:filter=filter />
</template>

<script>
import DataTable from '../commons/DataTable.vue';

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      object: 'locations',
      fields: [ 
        { 'name': 'id', 'caption': '#' },
        { 'name': 'name', 'caption': 'Nome' },
        { 'name': 'inative', 'caption': 'Inativo', 'alias': ['Não', 'Sim'] },
      ],
      routeTo: 'locationEdit',
      routeNew: 'locationNew',
      filter: 'name',
    };
  },
}
</script>
